import React, { lazy, Suspense } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { LayoutSplashScreen } from "_metronic/layout";

const UsersPage = lazy(
  () => import("./modules/UsersManagement/pages/UsersManagementPage")
);
const UsersBlockPage = lazy(
  () => import("./modules/UsersManagement/pages/UserBlock")
);
const CategoryPage = lazy(() => import("Category"));
const TagPage = lazy(() => import("Tag"));
const VideoPage = lazy(() => import("Video"));
const IngredientPage = lazy(() => import("Ingredient"));
const GroupIngredientPage = lazy(() => import("GroupIngredient"));
const CreatorPage = lazy(() => import("Creator"));
const WeeklyPage = lazy(() => import("Weekly"));
const CuratedPage = lazy(() => import("Curated"));
const CuratedPostPage = lazy(() => import("CuratedPost"));
const CuratedIngredientPage = lazy(() => import("CuratedIngredient"));
const NotificationPage = lazy(() => import("Notification"));
const PushPage = lazy(() => import("Push"));
const BannerPage = lazy(() => import("Banner"));
const TermConditionPage = lazy(() => import("TermCondition"));
const AppVersionPage = lazy(() => import("AppVersion"));
const CommentPage = lazy(() => import("Comment"));
const PopupPage = lazy(() => import("Popup"));
const ProductPage = lazy(() => import("Product"));
const PollPage = lazy(() => import("Poll"));
const StorePage = lazy(() => import("Store"));
const DashboardPage = lazy(() => import("Dashboard"));
const StatisticPage = lazy(() => import("Statistic/pages/StatisticPage"));
const IntroducePage = lazy(() => import("Introduce"));
const QuestionPage = lazy(() => import("Question"));
const NewsLetterPage = lazy(() => import("NewsLetter"));
const CreatorBlogPage = lazy(() => import("CreatorBlog"));
const BlogPage = lazy(() => import("Blog"));
const BadgePage = lazy(() => import("Badge"));
const PinManagement = lazy(() => import("PinManagement"));
const Splash = lazy(() => import("Splash"));
const AdvertiserList = lazy(() => import("Advertiser/List"));
const AdvertiserNotice = lazy(() => import("Advertiser/Notice"));
const AdvertiserQNA = lazy(() => import("Advertiser/QNA"));
const AdvertiserServiceGuide = lazy(() => import("Advertiser/ServiceGuide"));
const AdvertiserCreatorList = lazy(() => import("Advertiser/CreatorList"));
const AdvertiserCampaignList = lazy(() => import("Advertiser/CampaignList"));
const AdvertiserChatReport = lazy(() => import("Advertiser/ChatReport"));
const Notifications = lazy(() => import("./modules/Notifications"));

export default function BasePage() {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        <Redirect exact from="/" to="/dashboard" />
        <Route path="/dashboard" component={DashboardPage} />
        <Route path="/user-management" component={UsersPage} />
        <Route path="/user/block" component={UsersBlockPage} />
        <Route path="/category" component={CategoryPage} />
        <Route path="/tag" component={TagPage} />
        <Route path="/video" component={VideoPage} />
        <Route path="/ingredient" component={IngredientPage} />
        <Route path="/group-ingredient" component={GroupIngredientPage} />
        <Route path="/creator" component={CreatorPage} />
        <Route path="/weekly" component={WeeklyPage} />
        <Route path="/curated" component={CuratedPage} />
        <Route path="/post-curated" component={CuratedPostPage} />
        <Route path="/ingredient-curated" component={CuratedIngredientPage} />
        <Route path="/group-ingredient" component={GroupIngredientPage} />
        <Route path="/notification" component={NotificationPage} />
        <Route path="/push" component={PushPage} />
        <Route path="/poll" component={PollPage} />
        <Route path="/store" component={StorePage} />
        <Route path="/term_condition" component={TermConditionPage} />
        <Route path="/app-version" component={AppVersionPage} />
        <Route path="/banner" component={BannerPage} />
        <Route path="/popup" component={PopupPage} />
        <Route path="/product" component={ProductPage} />
        <Route path="/comment/:type" component={CommentPage} />
        <Route path="/statistic" component={StatisticPage} />
        <Route path="/introduce" component={IntroducePage} />
        <Route path="/question" component={QuestionPage} />
        <Route path="/newsletter" component={NewsLetterPage} />
        <Route path="/creatorblog" component={CreatorBlogPage} />
        <Route path="/blog" component={BlogPage} />
        <Route path="/badge" component={BadgePage} />
        <Route path="/pin-management" component={PinManagement} />
        <Route path="/splash" component={Splash} />
        <Route path="/advertiser-list" component={AdvertiserList} />
        <Route path="/advertiser-notice" component={AdvertiserNotice} />
        <Route path="/notifications" component={Notifications} />
        <Route path="/advertiser-qna" component={AdvertiserQNA} />
        <Route
          path="/advertiser-service-guide"
          component={AdvertiserServiceGuide}
        />
        <Route
          path="/advertiser-creator-list"
          component={AdvertiserCreatorList}
        />
        <Route
          path="/advertiser-campaign-list"
          component={AdvertiserCampaignList}
        />
        <Route path="/chat-report-list" component={AdvertiserChatReport} />
        <Redirect to="/error/error-v1" />
      </Switch>
    </Suspense>
  );
}
